@import '@aws-amplify/ui-react/styles.css';

.amplify-button--fullwidth {
  background-color: #005d9e !important;
}
.amplify-tabs__item--active {
  color: #005d9e !important;
}
.amplify-tabs__item--active {
  border-color: #005d9e !important;
}
.amplify-button--link {
  color: #005d9e !important;
}

.amplify-button--primary {
  background-color: #005d9e !important;
}
