body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* input[type=text] {
  width: 80%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  box-sizing: border-box;
}

label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}

input[type=submit] {
  background-color: #04AA6D;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: left;
} */

.container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding-top: 20px;
  padding-left: 160px;
  padding-right: 160px;
}

.col-25 {
  float: left;
  width: 15%;
  margin-top: 6px;
  text-align: right;
}

.col-30 {
  float: left;
  width: 30%;
  margin-top: 6px;
  text-align: right;
}

.col-70 {
  float: right;
  width: 70%;
  margin-top: 6px;
  text-align: left;
}

.col-75 {
  float: left;
  width: 85%;
  margin-top: 6px;
  text-align: left;
}

/* Clear floats after the columns */
.row::after {
  content: "";
  display: table;
  clear: both;
}
